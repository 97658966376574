<div class="hub-dialog">
    <app-loader *ngIf="isLoading" [app]="'hub'"></app-loader>

    <div class="hub-dialog-close">
        <svg [ngClass]="isLoading ? 'not-active' : ''" (click)="close()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
        </svg>
    </div>

    <div *ngIf="page == 1" class="hub-dialog-title">
        Here are some of the upgrades
    </div>
    <div *ngIf="page == 2" class="hub-dialog-title">
        In the new version you'll find
    </div>

    <div class="hub-dialog-content">
        <ng-container *ngIf="page == 1">
            <div class="hub-dialog-content-row bottom-align">
                <img class="right-align" src="../../../assets/images/hub/infographic_one.svg">
                <img src="../../../assets/images/hub/infographic_two.svg">
            </div>
            <div class="hub-dialog-content-row">
                <img class="right-align" src="../../../assets/images/hub/infographic_three.svg">
                <img src="../../../assets/images/hub/infographic_four.svg">
            </div>
        </ng-container>

        <ng-container *ngIf="page == 2">
            <div class="hub-dialog-content-features">
                <div class="hub-dialog-content-features-item" *ngFor="let feature of hubFeatures">
                    <div *ngIf="feature.label" class="hub-dialog-content-features-item-icon">
                        <img src="../../../assets/images/hub/{{feature.label}}" >
                    </div>
                    <div class="hub-dialog-content-features-item-label">
                        <div class="hub-dialog-content-features-item-label-name">{{feature.name}}</div>
                        <div class="hub-dialog-content-features-item-label-status" *ngIf="feature.comingSoon">Coming soon</div>
                    </div>
                </div>
            </div>

            <div class="hub-dialog-content-banner">
                <svg class="mr5" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99993 1.42856C3.4752 1.42856 1.4285 3.47526 1.4285 5.99999C1.4285 8.52472 3.4752 10.5714 5.99993 10.5714C8.52466 10.5714 10.5714 8.52472 10.5714 5.99999C10.5714 3.47526 8.52466 1.42856 5.99993 1.42856ZM0.285645 5.99999C0.285645 2.84408 2.84402 0.285706 5.99993 0.285706C9.15584 0.285706 11.7142 2.84408 11.7142 5.99999C11.7142 9.1559 9.15584 11.7143 5.99993 11.7143C2.84402 11.7143 0.285645 9.1559 0.285645 5.99999ZM5.4285 3.71428C5.4285 3.39869 5.68434 3.14285 5.99993 3.14285H6.00564C6.32124 3.14285 6.57707 3.39869 6.57707 3.71428C6.57707 4.02987 6.32124 4.28571 6.00564 4.28571H5.99993C5.68434 4.28571 5.4285 4.02987 5.4285 3.71428ZM4.85707 5.99999C4.85707 5.6844 5.11291 5.42856 5.4285 5.42856H5.99993C6.31552 5.42856 6.57136 5.6844 6.57136 5.99999V7.71428C6.88695 7.71428 7.14279 7.97011 7.14279 8.28571C7.14279 8.6013 6.88695 8.85713 6.57136 8.85713H5.99993C5.68434 8.85713 5.4285 8.6013 5.4285 8.28571V6.57142C5.11291 6.57142 4.85707 6.31558 4.85707 5.99999Z" fill="#356FE3"/>
                </svg>
                    
                <div>
                    You can switch to legacy Arc if needed.
                </div>
            </div>
        </ng-container>
    </div>

    <div class="hub-dialog-footer" [ngClass]="isLoading ? 'not-active' : ''">
        <div class="hub-dialog-footer-page">
            <button *ngIf="page == 2" (click)="setPage(1)" class="hub-button flat mr24">
                Back
            </button>
            <span>{{page}} of 2</span>
        </div>
        <div class="hub-dialog-footer-action">
            <button *ngIf="page == 1" (click)="setPage(2)" class="hub-button flat">
                Next
            </button>
            <button *ngIf="page == 2" class="hub-button" (click)="switchToHub()">
                Switch to the new version
            </button>
        </div>
    </div>
</div>