<div class="content-wrap">
    <div id="content" class="content admin_tool_content">
        <div class="admin_certification_body pt30">
            <h3 class="certificationWrapper">Review Order Update<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <header>
                    <h5 class="mb10 pt30">Please provide the details below to update the review details. Please use this <a href="../../assets/pdf/Review-order.pdf" download>guide</a> to learn how to use this tool.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-5 control-label">Property ID (starting with 4) or LEED ID (starting with 1 or 8) or Project ID (starting with 5)</label>
                                <div class="col-sm-6">                                    
                                    <!-- <input [(ngModel)]="delete_leed_id" type="text" id="normal-field" class="form-control" placeholder="LEED ID"> -->
                                    <input [(ngModel)]="leed_id" name= "leed_id" type="text" class="form-control" placeholder="Property ID or LEED ID or Project ID">
                                </div>
                                <div class="wizard display-block">
                                    <button (click)="getOrders()" class="btn btn-default pull-left mr10"><i class="fa fa-search" aria-hidden="true"></i> Search</button>
                                </div>
                            </div>                    
                        </fieldset>
                    </form>
                    <table class="table table-striped table-hover no-footer">
                        <thead>
                            <tr>
                                <th>Payment Date</th>
                                <th>Order Number</th>
                                <th>Actual Amount</th>
                                <th>Amount Paid</th>
                                <th>Quantity</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payments of mypayments; let idx = index" >
                                <td>
                                    <textarea rows=1 style="resize: none;" class="input-sm input-port " 
                                    [ngClass]="selected_row != payments.id ? 'not-active' : ''"  [(ngModel)]="payments.payment_date" id="payment_date" [readonly]="showThis" 
                                    [disabled]="showThis" value={{payments.payment_date}}>
                                    </textarea>
                                </td>
                                <td>
                                    <textarea rows=1 style="resize: none;" class="input-sm input-port " 
                                    [ngClass]="selected_row != payments.id ? 'not-active' : ''"  [(ngModel)]="payments.order_number" [readonly]="showThis" 
                                    [disabled]="showThis" value={{payments.order_number}} >
                                    </textarea>
                                </td>
                                <td>
                                    <textarea rows=1 style="resize: none;" class="input-sm input-port " 
                                    [ngClass]="selected_row != payments.id ? 'not-active' : ''"  [(ngModel)]="payments.actual_amount" [readonly]="showThis" 
                                    [disabled]="showThis" value={{payments.actual_amount}} >
                                    </textarea>
                                </td>
                                <td>
                                    <textarea rows=1 style="resize: none;" class="input-sm input-port " 
                                    [ngClass]="selected_row != payments.id ? 'not-active' : ''"  [(ngModel)]="payments.amount_paid" [readonly]="showThis" 
                                    [disabled]="showThis" value={{payments.amount_paid}} >
                                    </textarea>
                                </td>
                                <td>
                                    <textarea rows=1 style="resize: none;" class="input-sm input-port " 
                                    [ngClass]="selected_row != payments.id ? 'not-active' : ''" [(ngModel)]="payments.quantity" [readonly]="showThis" 
                                    [disabled]="showThis" value={{payments.quanity}} >
                                    </textarea>
                                </td>
                                <td>
                                    <textarea rows=1 style="resize: none;" class="input-sm input-port " 
                                    [ngClass]="selected_row != payments.id ? 'not-active' : ''" [(ngModel)]="payments.status" [readonly]="showThis" 
                                    [disabled]="showThis" value={{payments.status}} >
                                    </textarea>
                                </td>
                                <td>
                                    <div>
                                        <button class="btn btn-primary" *ngIf="selected_row != payments.id" (click)="editOrder(idx, payments)">Edit</button>
                                        <button class="btn btn-primary"  *ngIf="!showThis && selected_row == payments.id" (click)="updateOrder(idx, payments)"> {{save_text}}</button>
                                    </div>
                                    <!-- <button class="btn btn-primary" (click)="saveChanges()">Save</button> -->
                                    <!-- <div title="Edit Order" class="ml10 floatNone" (click)="updateOrder()">Edit Order</div> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    
   
        <div class="admin_certification_body pt30">
            <h3 class="certificationWrapper">Add Review Payment Order<span class="fw-semi-bold"></span></h3>
            <section class="widget widget_content">
                <header>
                    <h5 class="mb10 pt30">Please provide the details below to add the review details.</h5>
                </header>
                <div class="widget-body">
                    <form class="form-horizontal" role="form">
                        <fieldset>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Property ID (starting with 4) or LEED ID (starting with 1 or 8) or Project ID (starting with 5)</label>
                                <div class="col-sm-6">  
                                    <input [(ngModel)]="leed_id_review" name= "leed_id_review" type="text" class="form-control" placeholder="Property ID or LEED ID or Project ID">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Order Number</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsReview()" [(ngModel)]="formdata.order_number" name="order_number" type="text" id="order_number" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Order Status</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsReview()" [(ngModel)]="formdata.status" name="status" type="text" id="status" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">First Name</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsReview()" [(ngModel)]="formdata.first_name" name="first_name" type="text" id="first_name" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Last Name</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsReview()" [(ngModel)]="formdata.last_name" name="last_name" type="text" id="last_name" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Street</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsReview()" [(ngModel)]="formdata.street" name="street" type="text" id="street" class="form-control">
                                </div>
                            </div>  
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">City</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input maxlength="40" (input)="checkInputsReview()" class="form-control" [(ngModel)]="formdata.city" name="city" type="text" required>
                                </div>
                            </div>  
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">State</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <select autocomplete="false" [(ngModel)]="formdata.state" name="state" class="form-control" (change)="updateState(formdata.state)">
                                        <option *ngFor="let state of states" value="{{state.code}}">{{state.name}}</option>
                                    </select>
                                </div>
                            </div>  
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Country</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <select name="country" [(ngModel)]="formdata.country" (change)="updateCountry(formdata.country)" class="form-control" data-parsley-trigger="change">
                                        <option *ngFor="let country of countries" [value]="country.code" >{{country.name}}</option>
                                    </select>
                                </div>
                            </div>  
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Zipcode</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsReview()" [(ngModel)]="formdata.zip_code" name="zip_code" type="text" id="street" class="form-control">
                                </div>
                            </div> 
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Phone number</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input (input)="checkInputsReview()" [(ngModel)]="formdata.phone" name="phone" type="text" id="street" class="form-control">
                                </div>
                            </div>  
                            <div class="form-group">
                                <label for="normal-field" class="col-sm-4 control-label">Bill-To-Party Email</label>
                                <div class="col-sm-6" [ngClass]="loaderCert ? 'not-active' : ''">
                                    <input maxlength="241" (input)="checkInputsReview()" class="form-control" [(ngModel)]="formdata.email" name="email" type="text" required maxlength="241">
                                </div>
                            </div>   
                            <div class="form-group">
                                <label class="col-sm-4 control-label" for="prepended-input"></label>
                                <div class="col-sm-6">
                                    <button (click)="saveNewOrder()" [ngClass]="checkInputReview ? '' : 'not-active'" class="btn btn-default pull-left mr10">
                                        <i class="icon-save" aria-hidden="true"></i> Save
                                    </button>
                                    <div *ngIf="loaderCert" class="dataInputLoaderSpinner pull-left"></div>
                                </div>
                            </div>

                        </fieldset>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>
