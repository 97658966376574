import { Component, Input, OnInit } from '@angular/core';

import { AppData } from '../services/appdata.service';
import { ValidationService } from '../services/validation.service';
import { API } from '../services/api.service';
import { Global } from '../services/global.service';
import { InsightService } from '../services/insight.service';

import { ArcAuthService } from '../services/auth.service';
import { Config } from '../models/env-vars.model';
import { LanguageService } from '../services/language.service';
import { alertService } from '../services/alert-service.service';
import { AppBootstrapService } from '../services/app-bootstrap.service';

import * as FS from '@fullstory/browser';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom, take } from 'rxjs';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit
{
	@Input() mode: string = 'arc';
	config = Config;
	login_btn_text: any;
	agreement_url: string;
	email: string = '';
	password: string = '';
	form_submitted: boolean = false;
	error_msz: string= "";
    error_flag: boolean= false;
	form_error: any = {
		"email": false,
		"password": false
	};
	loading: boolean = false;

	constructor(
		private validator: ValidationService,
		private api: API,
		private appData: AppData,
		private arcAuth: ArcAuthService,
		public insight: InsightService,
		public global: Global,
		private lang: LanguageService,
		public auth0: AuthService,
		private alertService: alertService,
		private bootstrap: AppBootstrapService
	)
	{
		this.lang.getTranslation(Config.isAuthenticated ? 'Access tool' : 'Login').subscribe(
			response =>
			{
				this.login_btn_text = response;
			}
		);
		this.agreement_url = '../../assets/pdf/Arc End User License Agreement.pdf?embedded=true';
	}

	validate_input(key: string, data:string, event: any): void
	{
		this.error_flag = false;
		if(!this.validator.validate(data, key))
		{
			event.classList.add('error-border');
			this.form_error[key] = true;
		}
		else
		{
			event.classList.remove('error-border');
			this.form_error[key] = false;
		}
	}

	login(): void
	{
		this.form_submitted = true;
		this.validate_input('email', this.email, document.getElementsByName('email')[0]);
		this.validate_input('password', this.password, document.getElementsByName('password')[0]);

		if(!this.form_error.email && !this.form_error.password)
		{
			this.getToken();
		}
	}

	getToken(): void
	{
		let headers = {
			"Content-Type": "application/json",
			"Ocp-Apim-Subscription-Key": Config.sub_key,
			"Cache-Control": "no-cache",
			"Cache-Pragma": "no-cache",
			"If-Modified-Since": String(new Date().getTime())
		};
		let payload: any = {
			"username": this.email,
			"password": this.password
		};
		if(this.mode == 'admin')
		{
			payload["app"] = "admin_tool";
		}
		else if(this.mode == 'arc_japan_invoice')
		{
			payload["app"] = "arcjp_admin_tool";
		}
		else if(this.mode == 'cagbc_invoice')
		{
			payload["app"] = "cagbc_review_admin";
		}
		this.loading = true;
		this.login_btn_text = "Logging In...";
		this.api.post(
			'/auth/login/', 
			payload,
			{"headers": headers
		}).subscribe(data =>
		{
			this.appData.set('insight_session', undefined);
			this.appData.set('token', data.authorization_token);
			this.appData.set('user_pkey', data.user.id);
			this.appData.set('user_id', data.user.username);
			this.appData.set('user_guid', data.guid);
			this.appData.set('user_firstname', data.user.first_name);
			this.appData.set('user_lastname', data.user.last_name);
			this.appData.set('user_name', data.user.first_name + " " + data.user.last_name);
			this.appData.set('username', data.user.first_name + " " + data.user.last_name);
			this.appData.set('authorization_token', data.authorization_token);
			this.appData.set('end_user_agreement', data.signed_agreement);
			this.appData.set('is_admin', data.is_admin);
			this.appData.set('user_role', data.user_role);
			this.appData.set('profileimg', data.image);
			this.appData.set('gsa_admin', data.gsa_admin );
			this.appData.set('is_gsa_user', data.is_gsa_user );
			this.appData.set("selected_projects_session", []);
			this.appData.set('closed_score_version_notification', []);
			this.appData.set('LO_referer', false);
			this.appData.set('closed_message_banner', false);
			this.appData.set('user_type', this.global.getURLFromParam('user_type'));
			
			this.api.setAuth(data.authorization_token);

			if (!data.end_user_agreement_signed)
			{
				this.error_msz      = "";
				this.error_flag     = false;
				$("#end-user-agreement").modal('show');
				return false;
			}

			this.arcAuth.getAuth().subscribe(data => 
			{
				if(data.ItRoles[0].AgrName == "Z_LEEDV3_GBCIADMIN" || data.ItRoles[0].AgrName == "Z_LEEDV3_USGBCADMIN" || data.ItRoles[0].AgrName == "Z_LEEDV3_GBCICBADMIN" || data.ItRoles[0].AgrName == "Z_LEEDV3_CBADMIN")
					this.appData.set('is_user_gbci_usgbc_admin', true);
				else
					this.appData.set('is_user_gbci_usgbc_admin', false);
			});

			this.arcAuth.getLOToken().subscribe(data =>
			{
				this.appData.set('LO_key', data.key);
			})

			this.global.login_complete();

			FS.identify(data.user.username,
			{
				displayName: data.user.username
			});
		},
		error => {
			this.loading = false;
			this.login_btn_text = Config.isAuthenticated ? 'Access tool' : 'Login';
			if (error.error.non_field_errors){
				this.error_msz = error.error.non_field_errors[0];
			}
			else{
				this.error_msz = error.error;
			}
			
            this.error_flag = true;
		});
	}

	acceptedArcCookiesPolicy()
	{
		var d = new Date();
		d.setTime(d.getTime() + (8*24*60*60*1000));
		var expires = "expires="+ d.toUTCString();
		var cookie_div: any = document.getElementById("cookies_confirmation");
		cookie_div.className = cookie_div.className.replace("show", "hide");
		document.cookie = "agreed-arc-cookie=1; " + expires;
	}

	ngOnInit(): void
	{
		if(this.mode == 'arc')
		{
			window.location.replace(Config.landingPageURL);
		}
	}

	async loginWithRedirect(action?: string)
	{
		this.bootstrap.getAuth0();
	}
}
