<div id="plaque-component">
	<div id="project-info" class="pl15" [hidden]="!isNotHardware">
		<p class="mb5 fs16 project-title" id="name">{{name}}</p>
		<!-- <p class="mb5 mt0 fs12 project-address">{{street}} {{state}} {{country}}</p> -->
		<!-- <p class="mb5 mt10 fs12 project-address">Current Score: {{total_score}}</p> -->	
		<p class="mb5 mt0 fs12 project-address" *ngIf="last_certified_date != undefined">{{lang.getText("last_cert")}}: {{global.dateFormat(last_certified_date)}}</p>
		<p class="mb5 mt0 fs12 project-address" *ngIf="last_certified_score != undefined">{{lang.getText("level_cert")}}: <span class="capitalize">{{last_certified_level}}</span></p>
	</div>
	
	<div id="container" style="display: none;">
		<div class="temp"> <img class="plaque_top_temp" src="../../assets/images/LEED-plaques/plaque_big_score_blank_front.svg" /> </div>
		<div id="racetrack"></div>
		<div class="flip-container">
			<div class="flipper">
				<div class="front"> 
					
					<div *ngIf="cert=='certified'"></div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_certified.svg" />
					<div *ngIf="cert=='blank'" ></div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_blank.svg" />
					<div *ngIf="cert=='confidential'" ></div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_blank.svg" />
					<div *ngIf="cert=='gold'"></div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_gold.svg" />
					<div *ngIf="cert=='silver'"></div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_silver.svg" />
					<div *ngIf="cert=='platinum'"></div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_platinum.svg" />
					<div *ngIf="cert == ' ' || cert == '' || cert == 'None' || cert == null || cert == 'Denied'"></div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_nonleed.svg" />
					
					
				</div>
				<div class="back">
					<div *ngIf="cert=='certified'" class="plaque_score">65</div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_certified.svg" />
					<div *ngIf="cert=='blank'" class="plaque_score">65</div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_blank.svg" />
					<div *ngIf="cert=='confidential'" class="plaque_score">65</div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_blank.svg" />
					<div *ngIf="cert=='gold'" class="plaque_score">65</div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_gold.svg" />
					<div *ngIf="cert=='silver'" class="plaque_score">65</div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_silver.svg" />
					<div *ngIf="cert=='platinum'" class="plaque_score">65</div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_platinum.svg" />
					<div *ngIf="cert == ' ' || cert == '' || cert == 'None' || cert == null || cert == 'Denied'" class="plaque_score">65</div> <img class="plaque_top" src="../../assets/images/LEED-plaques/plaque_big_score_nonleed.svg" />
				 </div>
		</div>
		<div id="score-puck">
			<div class="text">0</div>
		</div>
	</div>
</div>