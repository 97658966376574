<div class="content-wrap">
    <div id="content" class="content">
        <nav class="page-controls navbar_info navbar-default flex" style="top: 0px;">
            <div class="align-center mt11 width-100-perc">
                <h4 class="fw-500" translate>{{title}}</h4>            
            </div>
            <div class="pt20">
                <svg (click)="goBack()" class="svg_hover" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                    <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
            </div>
        </nav>

        <div id="arc-jp-invoice-final" [hidden]="true">
            <div id="arc-jp-invoice">
                <head>
                    <meta charset="utf-8">
                </head>
                <style type="text/css">
                    @font-face 
                    {
                        src: url(https://api.usgbc.org/qas/leed/static/fonts/GOTHIC.TTF?subscription-key=5a41833022f046dc951383ead38d16d3);
                        font-family: Gothic;
                    }
                    @font-face 
                    {
                        src: url(https://api.usgbc.org/qas/leed/static/fonts/GOTHICB.TTF?subscription-key=5a41833022f046dc951383ead38d16d3);
                        font-family: GothicBold;
                    }
                    #arcjp_invoice.body_padding
                    {
                        font-family: Gothic;
                        padding: 50px 60px;
                        width: 8in;
                        height: 12in;
                
                        border: 1px solid black;
                    }
                    #arcjp_invoice .arc_logo
                    {
                        background: url(https://stg.app.arconline.io/assets/images/arc-logo-black.png) no-repeat;
                        background-size: contain;
                        background-position: right;
                        width: 100%;
                        height: 80px;
                        margin-bottom: 20px;
                    }
                    #arcjp_invoice .header_invoice
                    {
                        font-size: 22px;
                        border-bottom: 1px solid black;
                        text-align: center;
                        padding-bottom: 5px;
                        width: fit-content;
                        margin: 0px auto 20px auto;
                    }
                    #arcjp_invoice .mt20
                    {
                        margin-top: 20px;
                    }
                    #arcjp_invoice .bold
                    {
                        font-family: GothicBold;
                        font-weight: 500;
                    }
                    #arcjp_invoice .fs12
                    {
                        font-size: 12px;
                    }
                    #arcjp_invoice .left
                    {
                        text-align: left;
                    }
                    #arcjp_invoice .right
                    {
                        text-align: right;
                    }
                    #arcjp_invoice .center
                    {
                        text-align: center;
                    }
                    #arcjp_invoice .mb10
                    {
                        margin-bottom: 10px;
                    }
                    #arcjp_invoice .mb20
                    {
                        margin-bottom: 20px;
                    }
                    #arcjp_invoice .flex_end
                    {
                        display: flex;
                        justify-content: flex-end;
                    }
                    #arcjp_invoice p
                    {
                        margin: 0px 5px 5px 0px;
                    }
                    #arcjp_invoice .invoice_label
                    {
                        width: 100px;
                        text-align: left;
                    }
                    #arcjp_invoice .invoice_labelx2
                    {
                        width: 200px;
                        text-align: left;
                    }
                    #arcjp_invoice .invoice_data_pd
                    {
                        padding: 13px 0px;
                    }
                    #arcjp_invoice .invoice_data
                    {
                        width: 100px;
                        text-align: left;
                    }
                    #arcjp_invoice .header_invoice2
                    {
                        font-size: 14px;
                    }
                    #arcjp_invoice .flex
                    {
                        display: flex;
                    }
                    #arcjp_invoice .underline_inv
                    {
                        border-bottom: 1px solid black;
                        padding-bottom: 5px;
                        width: fit-content;
                    }
                    #arcjp_invoice table
                    {
                        width: 100%;
                        font-size: 12px;
                        border-collapse: collapse;
                    }
                    #arcjp_invoice thead
                    {
                        border: 1px solid black;
                    }
                    #arcjp_invoice .td_padded
                    {
                        padding: 20px 0px;
                    }
                    #arcjp_invoice td
                    {
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                        border-left: 1px solid black;
                        text-align: center;
                        padding: 5px 0px;
                    }
                    #arcjp_invoice th
                    {
                        border-right: 1px solid black;
                        padding: 5px 0px;
                    }
                    #arcjp_invoice .stripped
                    {
                        border: none;
                        vertical-align: bottom;
                    }
                    #arcjp_invoice .p0
                    {
                        padding: 0px;
                    }
                    #arcjp_invoice .footer_invoice
                    {
                        padding: 15px 15px;
                        width: 100%;
                        border: 1px solid;
                        font-size: 12px;
                    }
                    #arcjp_invoice .mb40
                    {
                        margin-bottom: 20px;
                    }
                    .invoice_row
                    {
                        display: flex;
                    }
                    .invoice_cell
                    {
                        padding: 5px 0px;
                        font-size: 12px;
                        text-align: center;
                        border: 1px solid black;
                        line-height: normal;
                        margin-bottom: -1px;
                        margin-left: -1px;
                    }
                    .invoice_cell.heading
                    {
                        font-weight: bold;
                    }
                    .invoice_cell.x2
                    {
                        padding: 20px 0px !important;
                    }
                    .invoice_cell.invisible
                    {
                        border: none !important;
                    }
                    .sub_cell {
                        padding: 5px 0px;
                        font-size: 12px;
                        text-align: center;
                        border: 1px solid black;
                        line-height: normal;
                        margin-bottom: -1px;
                        margin-left: -1px;
                        margin-top: -1px;
                        margin-right: -1px;
                    }
                </style>
                
                <div id="arcjp_invoice" class="body_padding">
                    <div class="arc_logo"></div>
                
                    <div class="header_invoice">ご請求書</div>
                
                    <div class="mt20 right mb40">
                        <div class="fs12 mb10 flex_end">
                            <div class="invoice_label">
                                <p>発行日</p>
                            </div>
                            <div class="invoice_data">
                                <span class="bold" id="date_issued">{{global.dateFormat()}}</span>
                            </div>
                        </div>
                        <div class="fs12 mb10 flex_end">
                            <div class="invoice_label">
                                <p>請求書番号</p>
                            </div>
                            <div class="invoice_data">
                                <span class="bold" id="invoice_id"></span>
                            </div>
                        </div>
                        <div class="fs12 flex_end">
                            <div class="invoice_labelx2">
                                <p>{{appData.get('username')}}</p>
                            </div>
                        </div>
                    </div>
                
                    <div class="header_invoice2 underline_inv bold mb40">
                        {{arc_japan_org}}
                    </div>
                
                    <div class="fs12 flex_end">
                        <div class="invoice_labelx2">
                            <p class="bold">株式会社Arc Japan</p>
                        </div>
                    </div>
                    <div class="fs12 mb10 flex_end">
                        <div class="invoice_labelx2">
                            <p class="">T7010001215580</p>
                        </div>
                    </div>

                    <div class="fs12 mb10 flex_end">
                        <div class="invoice_labelx2">
                            <p class="">〒102-0073</p>
                            <p class="">東京都千代田区九段北4-3-26</p>
                            <p class="">N-cross KUDAN 2F</p>
                        </div>
                    </div>
                    <div class="fs12 mb10 flex_end">
                        <div class="invoice_label">
                            <p>TEL: </p>
                        </div>
                        <div class="invoice_data">
                            <span>03-5213-4832</span>
                        </div>
                    </div>
                    <div class="fs12 mb40 flex_end">
                        <div class="invoice_label">
                            <p>FAX: </p>
                        </div>
                        <div class="invoice_data">
                            <span>03-5213-4451</span>
                        </div>
                    </div>
                
                    <div ng-hide="hide_project_invoice" class="fs12 mb10 flex hide_project">
                        <div class="invoice_label">
                            <p>Project name: </p>
                        </div>
                        <div class="invoice_data">
                            <span>{{appData.get('buildingData').name}}</span>
                        </div>
                    </div>
                
                    <div class="fs12 mb10 flex">
                        <div class="invoice_labelx2">
                            <p>以下のとおりご請求申し上げます。</p>
                        </div>
                    </div>
                
                    <div class="bold fs12 mb40 flex">
                        <div class="underline_inv invoice_label">
                            <p>ご請求額：</p>
                        </div>
                        <div class="underline_inv invoice_labelx2">
                            <span><span class="total">{{total_invoice}}</span>（消費税込）</span>
                        </div>
                    </div>
                
                    <div class="invoice_row">
                        <div style="width: 15%;" class="invoice_cell heading">納品日</div>
                        <div style="width: 50%;" class="invoice_cell heading">項目</div>
                        <div style="width: 15%;" class="invoice_cell heading">単価</div>
                        <div style="width: 5%;" class="invoice_cell heading">数量</div>
                        <div style="width: 15%;" class="invoice_cell heading">金額</div>
                    </div>
                    <div class="invoice_row">
                        <div style="width: 15%;" class="invoice_cell x2">{{global.dateFormat()}}</div>
                        <div style="width: 50%;" class="invoice_cell x2" id="product_name">{{product_name_invoice}}</div>
                        <div style="width: 15%;" class="invoice_cell x2">
                            <span class="price">
                                {{price_invoice}}
                            </span>
                        </div>
                        <div style="width: 5%;" class="invoice_cell x2">1</div>
                        <div style="width: 15%;" class="invoice_cell x2">
                            <span class="price">
                                {{price_invoice}}
                            </span>
                        </div>
                    </div>
                    <div class="invoice_row mb5">
                        <div style="width: 65%;text-align: left;position: relative;" class="invoice_cell invisible">
                            <span style="position: absolute;bottom: 5px;">
                                下記の銀行口座にお振り込みくださいますようお願い申し上げます。<br>
                                なお、恐れ入りますが振込手数料はお客様のご負担でお願いいたします。
                            </span>
                        </div>
                        <div style="width: 34.5%;padding: 0px;" class="invoice_cell">
                            <div class="invoice_row sub_cell">
                                <div style="width: 50%;">小計</div>
                                <div style="width: 50%;"><span class="price">{{price_invoice}}</span></div>
                            </div>
                            <div class="invoice_row sub_cell">
                                <div style="width: 50%;">消費税（10％)</div>
                                <div style="width: 50%;"><span class="price">{{tax_invoice}}</span></div>
                            </div>
                            <div class="invoice_row sub_cell">
                                <div style="width: 50%;">合計</div>
                                <div style="width: 50%;"><span class="price">{{total_invoice}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice_row">
                        <div style="width: 65%;text-align: left; padding:1px;" class="invoice_cell invisible">銀行名</div>
                    </div>
                    <div class="invoice_row">
                        <div style="width: 65%;text-align: left; padding:1px;" class="invoice_cell invisible">支店</div>
                    </div>
                    <div class="invoice_row">
                        <div style="width: 65%;text-align: left; padding:1px;" class="invoice_cell invisible">口座番号</div>
                    </div>
                    <div class="invoice_row mb40">
                        <div style="width: 65%;text-align: left; padding:1px;" class="invoice_cell invisible">口座名義</div>
                    </div>
                
                    <div class="invoice_row">
                        <div style="width: 35%;text-align: left; padding:1px;" class="invoice_cell invisible">三菱UFJ銀行</div>
                    </div>
                    <div class="invoice_row">
                        <div style="width: 35%;text-align: left; padding:1px;" class="invoice_cell invisible">日本橋中央支店　（３３３）</div>
                    </div>
                    <div class="invoice_row">
                        <div style="width: 35%;text-align: left; padding:1px;" class="invoice_cell invisible">(普）０３８８４２５</div>
                    </div>
                    <div class="invoice_row mb40">
                        <div style="width: 35%;text-align: left; padding:1px;" class="invoice_cell invisible">カ）　アークジャパン</div>
                    </div>
                
                    <div class="footer_invoice">
                        <div class="bold  mb10">■通信欄■</div>
                        <div class="">
                            <span id="due_data">{{global.dateFormat(deadline)}}</span> までにお振込みをお願いいたします。
                        </div>
                        <div class=" mb10">
                            お振込みの際、振込人記入欄の先頭に、請求書番号の記載をお願いいたします。
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #arc_pro_search_options>
            <div class="arc-pro-add-user">
                <div class="fs16 fw-500 line_height_24 mb8">
                    Add a collaborator
                </div>
                <div class="relative-position">
                    <input autocomplete="off" (focus)="isSearch(true)" (ngModelChange)="searchUser(search_user)" [(ngModel)]="search_user" placeholder="Add a person" class="arc-pro-search" />
                    <svg class="arc-pro-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                    <div *ngIf="search_results.length > 0 && is_search" class="user-list">
                        <div [hidden]="selectedUser(user.email)" [ngClass]="user.user_role == 'arc_pro' ? 'darkgray' : ''" class="user-item" *ngFor="let user of search_results">
                            <div class="" [innerHTML]="getUser(user, search_user)"></div>
                            <button (click)="addUser(user)" *ngIf="!(user.user_role == 'arc_pro')" class="add-user-button">Add</button>
                            <div *ngIf="user.user_role == 'arc_pro'" class="fs14 pt3 fw-500">
                                <div class="arc_logo_racetrack"></div>
                                {{arc_pro_label}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="search_results.length == 0 && is_search && search_user.length > 0 && !searching_user" class="user-list">
                        <div class="user-item align-center fs16 gray_color_707479 block">
                            No user found
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div [ngClass]="loading ? 'not-active' : ''" class="flex res_block">
            <app-loader *ngIf="loading"></app-loader>
            <div class="w65p resw100p mb50">
                <div *ngIf="mode == 'add_certificate' || mode == 'edit_certificate_billing' || mode == 'renew_certificate' || mode == 'climate_risk_report' || mode == 'climate_risk_certificate'" class="mt24 payment-card sign_up">
                    <div class="align_lr">
                        <div>
                            <div class="fs16 grey fw600 mb10" translate>Product</div>
                            <div class="fs18 fw-500" translate>{{title}}</div>
                        </div>
                        <div *ngIf="mode == 'add_certificate' || mode == 'renew_certificate' || mode == 'climate_risk_report' || mode == 'climate_risk_certificate'" class="billing-cycle-options">
                            <div class="fs16 grey fw600 mb10" translate>Price</div>
                            <div class="fs15">
                                <div class="fw-500">
                                    {{config.currency}}{{global.formatNumberCommas(global.formatNumber(annual.price, 2))}}
                                </div>
                                <span class="fw-500 fw-semi-bold fs16" [hidden]="mode == 'climate_risk_report' || mode == 'climate_risk_certificate'" translate>Billed yearly</span>
                                <!-- <div class="discount">
                                    Save {{global.formatNumber(100 * (1 - ((annual.price / 12) / monthly.price)), 2)}}%
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="mode == 'purchase_arc_pro' || mode == 'add_subscription'" class="mt24 payment-card sign_up">
                    <div class="align_lr">
                        <div *ngIf="selected_users.length > 0" class="fs18 fw-500">Sign up for {{arc_pro_label}}</div>
                        
                        <ng-container *ngIf="selected_users.length == 0" [ngTemplateOutlet]="arc_pro_search_options">
                        </ng-container>

                        <div [hidden]="league_user" *ngIf="mode == 'purchase_arc_pro' && payment_type == 'all'" class="billing-cycle-options">
                            <div class="fs16 fw-500 mb15 line_height_24">Select billing cycle</div>
                            <div class="radio_buttons line_height_24 fs15 billing-cycle">
                                <div class="flex mt10">
                                    <div class="mr25">
                                        <span class="checkcontainer" [ngClass]="billing_cycle ? 'fw-500' : ''"  (click)="changeBillingCycle(true)">
                                            {{config.currency}}{{global.formatNumberCommas(monthly.price)}}
                                            <div class="duration_val">
                                                per month
                                            </div>
                                            <input type="radio" name="billing_cycle" value="true" [(ngModel)]="billing_cycle">
                                            <span style="top: 3px;" class="radiobtn" [ngClass]="billing_cycle ? 'bgblue': ''"></span>
                                        </span>
                                        <span [ngClass]="billing_cycle ? 'fw-500' : ''" class="fw-semi-bold pl26 fs16" translate>Billed monthly</span>
                                    </div>
            
                                    <div>
                                        <span class="checkcontainer" [ngClass]="!billing_cycle ? 'fw-500' : ''"  (click)="changeBillingCycle(false)">
                                            {{config.currency}}{{global.formatNumberCommas(global.formatNumber(annual.price / 12, 2))}}
                                            <div class="duration_val">
                                                per month
                                            </div>
                                            <input type="radio" name="billing_cycle" value="false" [(ngModel)]="billing_cycle">
                                            <span style="top: 3px;" class="radiobtn" [ngClass]="!billing_cycle ? 'bgblue': ''"></span>
                                        </span>
                                        <span [ngClass]="!billing_cycle ? 'fw-500' : ''" class="fw-semi-bold pl26 fs16">Billed yearly</span>
                                        <div class="discount">
                                            Save {{global.formatNumber(100 * (1 - (((annual.price + annual.tax) / 12) / (monthly.price + monthly.tax))), 2)}}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selected_users.length > 0" class="arc-pro-users mt15">
                        <table class="table table-striped arc-table">
                            <thead>
                                <tr>
                                    <th class="fw-500">Subscriber</th>
                                    <th class="fw-500">Email</th>
                                    <th class="fw-500">Amount</th>
                                    <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let user of selected_users; let idx = index">
                                    <td>
                                        <span class="fw-500">{{user.name}}</span>
                                    </td>
                                    <td>
                                        <span>{{user.email}}</span>
                                    </td>
                                    <td>
                                        <div class="price_val">
                                            {{config.currency}}{{league_user && idx > 0 ? global.formatNumberCommas(price.price_per_unit2) : global.formatNumberCommas(price.price_per_unit)}}
                                        </div>
                                        <div class="duration_val">
                                            {{billing_cycle ? 'per month' : 'per year'}}
                                        </div>
                                    </td>
                                    <td>
                                        <a class="align-right" (click)="removeUser(idx)">Remove</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            
                    <ng-container *ngIf="selected_users.length > 0" [ngTemplateOutlet]="arc_pro_search_options">
                    </ng-container>
                </div>
                
                <div *ngIf="mode == 'purchase_arc_pro' || mode == 'update_billing_information' || mode == 'add_certificate' || mode == 'edit_certificate_billing' || mode == 'renew_certificate' || mode == 'climate_risk_report' || mode == 'climate_risk_certificate'" class="mt16">
                    <div class="payment-card" [ngClass]="loading ? 'not-active' : ''">
                        <div id="details-form">
                            <table class="width-100-perc">
                                <thead>
                                    <tr>
                                        <th class="w20p"></th>
                                        <th class="w20p"></th>
                                        <th class="w20p"></th>
                                        <th class="w20p"></th>
                                    </tr>
                                </thead>
                                <tbody class="table-margin-20">
                                    <tr>
                                        <td colspan="1">
                                            <div class="">
                                                <span class="grey fontSize16 fw600" translate>Billing</span>
                                            </div>
                                        </td>
                                        <td colspan="3">
                                            <div class="left-right-align">
                                                <span class="fontSize16 fw-400" translate>Edit Billing Details</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td class="pt30" colspan="3">
                                            <div class="group" [ngClass]="paymentdata_error.first_name.error ? 'has-error' : ''">      
                                                <input maxlength="20" (input)="verifyField(paymentdata.first_name, 'first_name')" [(ngModel)]="paymentdata.first_name" name="first_name" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>First Name</label>
                                                <span class="error">{{paymentdata_error.first_name.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colspan="3">
                                            <div class="group" [ngClass]="paymentdata_error.last_name.error ? 'has-error' : ''">      
                                                <input maxlength="19" (input)="verifyField(paymentdata.last_name, 'last_name')" [(ngModel)]="paymentdata.last_name" name="last_name" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Last Name</label>
                                                <span class="error">{{paymentdata_error.last_name.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colspan="3">
                                            <div class="group" [ngClass]="paymentdata_error.email.error ? 'has-error' : ''">      
                                                <input maxlength="60" (input)="verifyField(paymentdata.email, 'email')" [(ngModel)]="paymentdata.email" name="email" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Email</label>
                                                <span class="error">{{paymentdata_error.email.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colspan="3">
                                            <div class="group" [ngClass]="paymentdata_error.street.error ? 'has-error' : ''">      
                                                <input maxlength="60" (input)="verifyField(paymentdata.street, 'street')" [(ngModel)]="paymentdata.street" name="street" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Address</label>
                                                <span class="error">{{paymentdata_error.street.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colspan="3">
                                            <div class="group" [ngClass]="paymentdata_error.city.error ? 'has-error' : ''">      
                                                <input maxlength="40" (input)="verifyField(paymentdata.city, 'city')" [(ngModel)]="paymentdata.city" name="city" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>City</label>
                                                <span class="error">{{paymentdata_error.city.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colspan="3">
                                            <div class="group" [ngClass]="paymentdata_error.phone.error ? 'has-error' : ''">      
                                                <input maxlength="40" (input)="verifyField(paymentdata.phone, 'phone')" [(ngModel)]="paymentdata.phone" name="phone" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Phone Number</label>
                                                <span class="error">{{paymentdata_error.phone.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colspan="1">
                                            <div class="select-group group pr20">   
                                                <label translate>Country/Region</label>   
                                                <select autocomplete="false" name="country" (change)="updateCountry(paymentdata.country)" [(ngModel)]="paymentdata.country">
                                                    <option *ngFor="let country of countries" value="{{country.code}}">{{country.name}}</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                            </div>
                                        </td>
                                        <td colspan="2">
                                            <div class="select-group group">   
                                                <label translate>State</label>   
                                                <select autocomplete="false" [(ngModel)]="paymentdata.state" name="state" (change)="updateState(paymentdata.state)">
                                                    <option *ngFor="let state of states" value="{{state.code}}">{{state.name}}</option>
                                                </select>
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colspan="3">
                                            <div class="group pr20" [ngClass]="paymentdata_error.zip_code.error ? 'has-error' : ''">      
                                                <input maxlength="10" (input)="verifyField(paymentdata.zip_code, 'zip_code')" [(ngModel)]="paymentdata.zip_code" name="zip_code" type="text" required>
                                                <span class="highlight"></span>
                                                <span class="bar"></span>
                                                <label translate>Zip Code</label>
                                                <span class="error">{{paymentdata_error.zip_code.help}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
    
                <div class="mt16 payment-card arc_pro_payment">
                    <div *ngIf="false" class="border-bottom">
                        <table id="details-form" class="width-100-perc border-bottom">
                            <thead>
                                <tr>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                </tr>
                            </thead>
                            <tbody class="table-margin-20">
                                <tr>
                                    <td colspan="1">
                                        <div class="">
                                            <span class="grey fw600 fontSize16">Promotional Code</span>
                                        </div>
                                    </td>
                                    <td class="flex" colspan="1">
                                        <div class="group pb0" [ngClass]="invalid_promo_code ? 'has-error': ''">      
                                            <input maxlength="35" [(ngModel)]="promocode" name="promocode" type="text" required>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>Enter Code</label>
                                            <span class="error">Invalid Promocode</span>
                                        </div>
                                        <div class="ml15 pt10" *ngIf="!promocode_applied">
                                            <button class="btn btn-primary btn-xs fs14 inverse">
                                                Apply
                                            </button>
                                        </div>
                                        <div *ngIf="promocode_applied" (click)="removePromocode(promocode_price.term)" data-tooltip-placement="top" data-tooltip="Remove Promotional Code" class="remove_promo cursor-pointer fa fa-times-circle-o"></div>
                                    </td>
                                    <td colspan="2"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
    
                    <div>
                        <table id="details-form" class="width-100-perc">
                            <thead>
                                <tr>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                    <th class="w20p"></th>
                                </tr>
                            </thead>
                            <tbody [hidden]="mode == 'edit_certificate_billing' || mode == 'update_billing_information'" class="table-margin-20 line_height_24">
                                <tr>
                                    <td>
                                        <div class="">
                                            <div class="grey fw600 fontSize16" translate>Total</div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="fw-500 fs16" translate>Billed Now</div>
                                    </td>
                                </tr>
                                <tr [hidden]="!(show_tax)" class="fs16">
                                    <td colspan="2"></td>
                                    <td>
                                        <div class="mb8" translate>Subtotal</div>
                                    </td>
                                    <td>
                                        <div class="align-right mb8">{{config.currency}}{{global.formatNumberCommas(price.price)}}</div>
                                    </td>
                                </tr>
                                <tr class="fs16" *ngIf="price.discounted_amount > 0">
                                    <td colspan="2"></td>
                                    <td>
                                        <div class="pb8 border-botton-light" translate>Discount</div>
                                    </td>
                                    <td>
                                        <div class="align-right pb8 border-botton-light">{{config.currency}}{{global.formatNumberCommas(price.discounted_amount)}}</div>
                                    </td>
                                </tr>
                                <tr [hidden]="!(show_tax)" class="fs16">
                                    <td colspan="2"></td>
                                    <td class="border-bottom">
                                        <div class="mb8" translate>Estimated Tax</div>
                                    </td>
                                    <td class="border-bottom">
                                        <div class="align-right mb8">{{config.currency}}{{global.formatNumberCommas(price.tax)}}</div>
                                    </td>
                                </tr>
                                <tr class="fs16">
                                    <td colspan="2"></td>
                                    <td>
                                        <div class="mb25 mt8" translate>Total due today</div>
                                    </td>
                                    <td>
                                        <div class="align-right mb25 fw-500 mt8">{{config.currency}}{{global.formatNumberCommas((price.price - price.discounted_amount) < 0 ? (0 + price.tax) : price.price - price.discounted_amount + price.tax)}}</div>
                                    </td>
                                </tr>
                                <tr *ngIf="terms_required == true">
                                    <td></td>
                                    <td colspan="3">
                                        <div *ngIf="mode != 'climate_risk_certificate'" class="fs13 gray_color_707479">
                                            <div *ngIf="price.discounted_amount == 0">
                                                You are choosing to sign up for automated renewal billing. The credit card you use today will be charged {{config.currency}}{{global.formatNumberCommas(price.price + price.tax)}} per {{billing_cycle ? 'month' : 'year'}} by Green Business Certification Inc. (GBCI) for your use of the {{type_label}} service. This credit card will be charged each {{billing_cycle ? 'month' : 'year'}} until you cancel the service. Our cancellation policy can be found <a (click)="global.openPolicy(type_label)">here</a>.
                                            </div>
    
                                            <div *ngIf="price.discounted_amount > 0">
                                                <div *ngIf="extra && billing_cycle">
                                                    You have received a discount for {{price.discount.months}} {{price.discount.months == 1 ? 'month' : 'months'}} for {{price.discount.count}} {{price.discount.count == 1 ? 'user' : 'users'}}. You are choosing to sign up for automated renewal billing. The credit card you use today will be charged {{config.currency}}{{global.formatNumberCommas(extra_charge)}} per month till {{free_until}} and {{config.currency}}{{global.formatNumberCommas(price.price + price.tax)}} per month from {{next_billing}} by Green Business Certification Inc. (GBCI) for your use of the {{type_label}} service. This credit card will be charged each month until you cancel the service. Our cancellation policy can be found <a (click)="global.openPolicy(type_label)">here</a>.
                                                </div>
                                                <div *ngIf="extra && !billing_cycle">
                                                    You have received a discount for {{price.discount.months}} {{price.discount.months == 1 ? 'month' : 'months'}} for {{price.discount.count}} {{price.discount.count == 1 ? 'user' : 'users'}}. You are choosing to sign up for automated renewal billing. The credit card you use today will be charged {{config.currency}}{{global.formatNumberCommas(extra_charge)}} for this year and {{config.currency}}{{global.formatNumberCommas(price.price + price.tax)}} per year from {{next_billing}} by Green Business Certification Inc. (GBCI) for your use of the {{type_label}} service. This credit card will be charged each year until you cancel the service. Our cancellation policy can be found <a (click)="global.openPolicy(type_label)">here</a>.
                                                </div>
    
                                                <div *ngIf="!extra">
                                                    You have received a discount for {{price.discount.months}} {{price.discount.months == 1 ? 'month' : 'months'}} for {{price.discount.count}} {{price.discount.count == 1 ? 'user' : 'users'}}. You will have free access to the tool till {{free_until}} and your next payment will be schedule on {{next_billing}}. You are choosing to sign up for automated renewal billing. The credit card you use today will be charged {{config.currency}}{{global.formatNumberCommas(price.price + price.tax)}} per {{billing_cycle ? 'month' : 'year'}} from {{next_billing}} by Green Business Certification Inc. (GBCI) for your use of the {{type_label}} service. This credit card will be charged each {{billing_cycle ? 'month' : 'year'}} until you cancel the service. Our cancellation policy can be found <a (click)="global.openPolicy(type_label)">here</a>.
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div *ngIf="mode == 'climate_risk_certificate'" class="fs13 gray_color_707479">
                                            <div *ngIf="price.discounted_amount == 0">
                                                The credit card you use today will be charged {{config.currency}}{{global.formatNumberCommas(price.price + price.tax)}} one-time purchase by Green Business Certification Inc. (GBCI) for your use of the {{type_label}} service. <a href="//arc.gbci.org/pricing" target="_blank" translate>Volume pricing is available</a>.
                                            </div>
    
                                            <div *ngIf="price.discounted_amount > 0">
                                                 <div *ngIf="!extra">
                                                    You have received a discount for {{price.discount.count}}. The credit card you use today will be charged {{config.currency}}{{global.formatNumberCommas(price.price + price.tax)}} one-time purchase by Green Business Certification Inc. (GBCI) for your use of the {{type_label}} service. <a href="//arc.gbci.org/pricing" target="_blank" translate>Volume pricing is available</a>.
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="payment_type == 'all'">
                                    <td colspan="1">
                                        
                                    </td>
                                    <td colspan="3">
                                        <div [hidden]="mode == 'climate_risk_report' || mode == 'climate_risk_certificate'" class="mt15 fw-500 fs16 mb15">Billed {{billing_cycle ? 'Monthly' : 'Yearly'}}</div>
                                    </td>
                                </tr>
                                <tr *ngIf="terms_required == true && mode != 'climate_risk_certificate'">
                                    <td></td>
                                    <td colspan="3">
                                        <div class="ml20 checkbox pl0 mt30">
                                            <input (click)="toggleFlag('recurring_payment')" style="width: initial;" type="checkbox" value="confidential">
                                            <label>
                                                I agree that GBCI may store my credit card information and may charge my credit card {{billing_cycle ? 'monthly' : 'annually'}} for the subscription price in effect at the time of renewal unless I cancel my subscription.
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="mode == 'climate_risk_report' || mode == 'climate_risk_certificate'">
                                    <td></td>
                                    <td class="align-right" colspan="3">
                                        <div (click)="submitPayment()" class="btn btn-primary " translate>
                                            {{purchase_button | translate}}
                                        </div>
                                        <p *ngIf="submit_error_payment" class="fw-600 danger-color mt10">Please provide valid data</p>
                                    </td>
                                </tr>
                                <tr *ngIf="mode == 'purchase_arc_pro' || mode == 'add_certificate' || mode == 'edit_certificate_billing' || mode == 'renew_certificate'">
                                    <td></td>
                                    <td class="align-right" colspan="3">
                                        <div [ngClass]="selected_users.length == 0 || !recurring_payment ? 'not-active' : ''" (click)="submitPayment()" class="btn btn-primary " translate>
                                            {{purchase_button | translate}}
                                        </div>
                                        <p *ngIf="submit_error_payment" class="fw-600 danger-color mt10">Please provide valid data</p>
                                    </td>
                                </tr>
                                <tr *ngIf="mode == 'update_billing_information'">
                                    <td></td>
                                    <td class="align-right" colspan="3">
                                        <div [ngClass]="selected_users.length == 0 || !recurring_payment ? 'not-active' : ''" (click)="submitPayment()" class="btn btn-primary " translate>
                                            {{purchase_button | translate}}
                                        </div>
                                        <p *ngIf="submit_error_payment" class="fw-600 danger-color mt10">Please provide valid data</p>
                                    </td>
                                </tr>
                                <tr [hidden]="payment_mode == 'arc_japan'" *ngIf="mode == 'add_subscription' || mode == 'renew_certificate'">
                                    <td></td>
                                    <td class="align-right" colspan="3">
                                        <div class="radio_buttons line_height_24 fs15 billing-cycle">
                                            <div class="flex flex-end mt10 mb15">
                                                <div class="mr25">
                                                    <span (click)="changeCC(saved_cc)" class="checkcontainer" [ngClass]="saved_cc ? 'fw-500' : ''">
                                                        Use saved card ending in {{subscriptions.card.last4}}
                                                        <input type="radio" name="saved_cc" value="true" [(ngModel)]="saved_cc">
                                                        <span class="radiobtn" [ngClass]="saved_cc ? 'bgblue': ''"></span>
                                                    </span>
                                                </div>
                        
                                                <div>
                                                    <span (click)="changeCC(saved_cc)" class="checkcontainer" [ngClass]="!saved_cc ? 'fw-500' : ''" >
                                                        Add new card
                                                        <input type="radio" name="saved_cc" value="false" [(ngModel)]="saved_cc">
                                                        <span class="radiobtn" [ngClass]="!saved_cc ? 'bgblue': ''"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="mode == 'add_subscription'">
                                    <td></td>
                                    <td class="align-right" colspan="3">
                                        <div [ngClass]="selected_users.length == 0 || !recurring_payment ? 'not-active' : ''" (click)="submitPayment()" class="btn btn-primary " translate>
                                            {{purchase_button | translate}}
                                        </div>
                                        <p *ngIf="submit_error_payment" class="fw-600 danger-color mt10">Please provide valid data</p>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody [hidden]="!(mode == 'edit_certificate_billing' || mode == 'update_billing_information')">
                                <tr>
                                    <td></td>
                                    <td class="align-right" colspan="3">
                                        <div [ngClass]="selected_users.length == 0 ? 'not-active' : ''" (click)="submitPayment()" class="btn btn-primary " translate>
                                            {{purchase_button | translate}}
                                        </div>
                                        <p *ngIf="submit_error_payment" class="fw-600 danger-color mt10">Please provide valid data</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        
            <div *ngIf="mode == 'purchase_arc_pro' || mode == 'add_subscription'" class="mt24 ml32 payment-card sign_up_info resw100p resml0">
                <div class="fs18 mb15 pt3 fw-500">
                    <div class="arc_logo_racetrack arc_logo_racetrack_big"></div>
                    <div style="position: relative;top: 5px;" translate>{{arc_pro_label}}</div>
                </div>
                <div class="fs15 pt15 mb16">
                    Arc Essentials is a subscription service. A few important details:
                </div>
                <ul class="fs15 line_height_22 gray_color_707479 pl20">
                    <li>Arc Essential tools can be used with any project associated with a user’s account</li>
                    <li>Users can purchase Arc Essentials for themselves or others</li>
                    <li>Users can elect to pay monthly or a discounted annual fee</li>
                    <li>Early cancelation of the monthly payment option will incur a cancelation fee</li>
                    <li>Please reach out to the <a (click)="global.openEmail('contact@arcskoru.com')">Arc team with any questions</a></li>
                </ul>
            </div>
    
            <div *ngIf="mode == 'update_billing_information'" class="mt24 ml32 payment-card sign_up_info fit-content resw100p resml0">
                <div class="fs15" translate>
                    Please note that changing your billing location could impact your applicable tax rate.
                </div>
            </div>
    
            <div *ngIf="mode == 'add_certificate' || mode == 'edit_certificate_billing' || mode == 'renew_certificate'" class="mt24 ml32 payment-card sign_up_info resw100p resml0">
                <div class="fs18 mb15 pt3 fw-500">
                    <div class="arc_logo_racetrack arc_logo_racetrack_big"></div>
                    <div style="position: relative;top: 5px;" translate>Arc Performance Certificate</div>
                </div>
                <div class="fs15 pt15 mb16" translate>
                    Arc Performance Certificates are a subscription service. A few important details:
                </div>
                <ul class="fs15 line_height_22 gray_color_707479 pl20">
                    <li translate>A Performance Certificate subscription is associated with a single project</li>
                    <li translate>The Performance Certificate feature is available to all members of the project team. They can all create, view, and download Performance Certificates</li>
                    <li translate>A Performance Certificate subscription for one project does not transfer to other projects in a user’s account</li>
                    <li translate>A subscription allows project team members to create an unlimited number of Performance Certificates for any or all categories</li>
                    <li translate>Performance Certificates can be shared as PDF documents</li>
                    <!-- <li>Users can elect to pay monthly or a discounted annual fee</li>
                    <li>Early cancelation of the monthly payment option will incur a cancelation fee</li> -->
                    <li translate>Please reach out to the <a (click)="global.openEmail('contact@arcskoru.com')" translate>Arc team with any questions</a></li>
                </ul>
            </div>
        </div>
    
        <div class="invoice_generated modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-md">
                <div class="modal-content p50 modal_radius">
                    <div class="flex mb25">
                        <div class="done_green_icon mr10"></div>
                        <h4 class="green-color fw-500 line_height_32 mt0">Your invoice has been successfully generated</h4>
                    </div>
                    <p class="fs16 line_height_24 mb16">
                        We have emailed a copy of the invoice to <span class="fw-500">{{appData.get('user_id')}}</span>. The invoice is also available in the billing page. Please follow the instructions in the invoice to make the payment.
                        <span [hidden]="!(receipt_url != null)"><br><br>Click <a (click)="downloadInvoice()">here</a> to download the invoice.</span>
                    </p>
                    <div class="mt30 align-center"> 
                        <button class="btn action-button btnGreen ccc_border" data-dismiss="modal" (click)='goBack()'>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="modal portfolio-modal-wbg review_not_allowed_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-md"><div class="modal-content"><div class="p2535">
            
            <div class="left-right-align mb20">
                <h4 class="mb10 mt10 fw-600">Purchase not allowed</h4> 
            </div>
        
            <div class="left-right-align fs16">
                <div class="danger-color">
                    <div>Payments for test projects not allowed.</div>
                </div>
            </div>
        
            <div class="mt30">
                <div class="align-left">
                    <button (click)="goBack()" data-dismiss="modal" type="button" class="btn btn-default btn-md">CLOSE</button>
                </div>
            </div>
        </div></div></div>
        </div>

    </div>
</div>